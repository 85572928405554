@use "include-media/dist/include-media"
@use "../../../../static/sass/library/fonts"
@use "../../../../static/sass/library/breakpoints"

.c18MessageBox
    border-top: 4px solid #e1001a
    font-family: fonts.$fontRubrik

    &__title
        font-size: 1.25rem
        line-height: 1.5rem
        text-transform: uppercase
        margin: 22px 12px 16px 12px
        font-weight: 700

        &--highlight
            color: #e1001a
            display: block

        &--regular
            color: black
            display: block
            font-weight: 500

    &__subtitle
        font-size: 0.9375rem
        margin: 10px 12px

    &__list
        display: flex
        flex-direction: column
        @include include-media.media("<min")
            margin: 0 15px

    &__listItemLink
        display: flex
        color: black
        text-decoration: none
        align-items: center
        border-top: 1px solid #dedede
        height: 85px
        padding: 0 6px

        &:hover
            .c18MessageBox
                &__listItemText
                    color: #777
                &__listItemIcon
                        opacity: 0.9

    &__listItemLink--last
        border-bottom: 1px solid #dedede

    &__listItemIconWrapper
        display: flex
        width: 90px
        margin-right: 8px
        height: 100%

    &__listItemIcon
        max-height: 100%
        max-width: 100%
        display: block
        margin: auto

    &__listItemText
        font-size: 0.9375rem
        line-height: 1.25rem
        font-weight: 400
